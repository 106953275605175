import React, { useEffect } from 'react';
import './main.css';
import img from '../../Assets/img1.jpg'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BsClipboardCheck } from 'react-icons/bs'
import './main.scss'
import img2 from '../../Assets/img2.jpg'
import img3 from '../../Assets/img3.jpg'
import img4 from '../../Assets/img4.jpg'
import img5 from '../../Assets/img5.jpg'
import img6 from '../../Assets/img6.jpg'
import img7 from '../../Assets/img7.jpg'
import img8 from '../../Assets/img8.jpg'
import img9 from '../../Assets/img9.jpg'
import Aos from 'aos'
import 'aos/dist/aos.css'


const data = [

    {
        id: 1,
        imgSrc: img,
        destTitle: 'Coxs Bazar',
        location: 'Bangladesh',
        grade: 'CULTURAL RELAX',
        fees: '8000Tk/==',
        description: 'Coxs Bazar sea beach is the longest sea beach in the world, 120 km long, having no 2nd instance. The wavy water of Bay of Bengal touches the beach throughout this 120 km. '
    },

    {
        id: 2,
        imgSrc: img2,
        destTitle: 'Bichanakandi,Sylhet',
        location: 'Bangladesh',
        grade: 'Natural RELAX',
        fees: '6000Tk/==',
        description: 'Bisanakandi is situated at Bangladesh-India border in Sylhet. It is a landscape beauty among gardens and hills. Bichanakandi is a village situated in Rustompur Union under Guainghat Upazilla. This is where many layers of the Khasi mountain meet at a single point from both sides.   '
    },

    {
        id: 3,
        imgSrc: img3,
        destTitle: 'Kuakata,Patuakhali',
        location: 'Bangladesh',
        grade: 'CULTURAL RELAX',
        fees: '7000Tk/==',
        description: 'Kuakata is a beach town known for its panoramic sea beach. It is in southeastern Bangladesh and is the number of sizeable tourist destinations in the country. Kuakata beach is a sandy expanse 18 kilometers (11 mi) long and 3 kilometers (1.9 mi) wide. From the beach, one can have an unobstructed view of both sunrise and sunset over the Bay of Bengal. '
    },

    {
        id: 4,
        imgSrc: img4,
        destTitle: 'Sazek Valley,Rangamati',
        location: 'Bangladesh',
        grade: 'Natural RELAX',
        fees: '7000Tk/==',
        description: 'Sajek Valley is one of the most popular tourist spots in Bangladesh situated among the hills of the Kasalong range of mountains in Sajek union, Baghaichhari Upazila in Rangamati District. The valley is 2,000 feet (610 m) above sea level.Sajek Valley is known as the Queen of Hills & Roof of Rangamati. '
    },

    {
        id: 5,
        imgSrc: img5,
        destTitle: 'Nafakhum,Bandarban',
        location: 'Bangladesh',
        grade: 'Tracking',
        fees: '8000Tk/==',
        description: 'Nafakhum is situated on the wild hilly Sangu River at a remote area called Remakri of Thanchi upazilla under Bandarban Hill District in Bangladesh. Nafakhum is also known as Remakri waterfall. It is among the largest waterfalls in Bangladesh by volume of water falling. '
    },

    {
        id: 6,
        imgSrc: img6,
        destTitle: 'Sundarban',
        location: 'Bangladesh',
        grade: 'Tracking',
        fees: '10000Tk/==',
        description: 'The Sundarbans is a vast forest in the coastal region of the Bay of Bengal which is one of the natural wonders of the world. Located in the delta region of Padma, Meghna and Brahmaputra river basins, this unique forest area extends across Khulna, Satkhira, Bagerhat, Patuakhali and Barguna districts. The Sundarbans is the largest forested forest in the world, as the largest mangrove forest in the coastal environment. '
    },

    {
        id: 7,
        imgSrc: img7,
        destTitle: 'Debotakhum,Bandarban',
        location: 'Bangladesh',
        grade: 'Tracking',
        fees: '8000Tk/==',
        description: 'Debotakhum is located in Roangchhari upazila of Bandarban district. Natural Bandarban is said to be the paradise of Khum and the crown of excellence of this kingdom will undoubtedly go to Devatakhum. According to the locals, this khum is about 50-60 feet deep and 700 feet long which is much bigger than Velakhum and much more wild. '
    },

    {
        id: 8,
        imgSrc: img8,
        destTitle: 'Keokradong,Bandarban',
        location: 'Bangladesh',
        grade: 'Tracking',
        fees: '8500Tk/==',
        description: 'Keokradong is the second highest mountain of Bangladesh. Keokradong is about 986 metres (3,235 ft) high from the sea level. It is situated about 30 km away from the Ruma Bazar upazilla of Bandarban in southeastern Bangladesh. This remote area is full and compacts of natural beauty. Where you can enjoy so many small and big mountains, hills and water falls. This area is covered with dense forests, birds, animals, tribal life, tribal village as well as happiest tribal people.This scenic beauty is absolutely attracts the mind of the adventurous people and you will lost your mind throughout the wild blowing scenarios. '
    },

    {
        id: 9,
        imgSrc: img9,
        destTitle: 'St. Martin’s Island,Chattagram',
        location: 'Bangladesh',
        grade: 'CULTURAL RELAX',
        fees: '9000Tk/==',
        description: 'St Martin’s Island a small island in the northeast of the bay of bengal, about 9 km south of the Cox’s Bazar-Teknaf peninsular tip and forming the southernmost part of Bangladesh. It is about 8 km west of the northwest coast of Myanmar at the mouth of the river naf. The island lies between 92°18 and 92°21E longitudes and 20°34 and 20°39N latitudes. The local people call it Narikel Jinjira. It is almost flat and is 3.6m above the mean sea level. The 9.66 km wide channel between the mainland and the island is much shallower than the open sea southwest of the island. There are reefs from 10-15 km to the west-northwest. '
    },
]

const Main = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return (
        <section className='main container section'>
            <div className='secTitle'>
                <h3 data-aos='fade-right' className='title'>
                    Most Visited Destinations
                </h3>
            </div>

            <div className='secContent grid'>
                {
                    data.map(({ id, imgSrc, destTitle, location, grade, fees, description }) => {
                        return (
                            <div data-aos='fade-up' className='singleDestination' key={id}>

                                <div className='imageDiv'>
                                    <img src={imgSrc} alt={destTitle} />
                                </div>
                                <div className='cardInfo'>
                                    <h4 className='desTitle'>
                                        {destTitle}
                                    </h4>
                                    <span className='continent flex'>

                                        <HiOutlineLocationMarker className='icon'></HiOutlineLocationMarker>
                                        <span className='name'>
                                            {location}
                                        </span>
                                    </span>

                                    <div className='fees flex'>
                                        <div className='grade'>
                                            <span>{grade}<small>+1</small></span>
                                        </div>
                                        <div className='price'>
                                            <h5>{fees}</h5>
                                        </div>
                                    </div>
                                    <div className='desc'><p>{description}</p></div>
                                    <button className='btn flex'>DETAILS <BsClipboardCheck className='icon'></BsClipboardCheck> </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    );
};

export default Main;