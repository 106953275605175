import React, { useEffect } from 'react';
import './footer.scss'
import video from '../../Assets/video2.mp4'
import { FiSend } from 'react-icons/fi'
import { MdTravelExplore } from 'react-icons/md'
import { AiOutlineTwitter } from 'react-icons/ai'
import { AiFillYoutube } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'
import { FaTripadvisor } from 'react-icons/fa'
import { FiChevronRight } from 'react-icons/fi'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Footer = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return (
        <section className='footer'>

            <div className='videoDiv'>

                <video src={video} loop autoPlay muted type='video/mp4'></video>
            </div>

            <div className='secContent container'>
                <div className='contactDiv flex'>
                    <div data-aos='fade-up' className='text'>
                        <small>KEEP IN TOUCH</small>
                        <h2>Travel with us</h2>
                    </div>
                    <div className='inputDiv flex'>
                        <input data-aos='fade-up' type="text" placeholder='Enter Email Address' />
                        <button data-aos='fade-up' className='btn flex' type='submit'>
                            SEND
                            <FiSend className='icon'></FiSend>
                        </button>
                    </div>
                </div>

                <div className='footerCard flex'>
                    <div className='footerIntro flex'>
                        <div className='logoDiv'>
                            <a href="#" className='logo flex'>
                                <MdTravelExplore className='icon'></MdTravelExplore>Travel.
                            </a>
                        </div>
                        <div data-aos='fade-up' className='footerParagraph'>
                            Why do you go away? So that you can come back. So that you can see the place you came from with new eyes and extra colors. And the people there see you differently, too. Coming back to where you started is not the same as never leaving.
                        </div>
                        <div data-aos='fade-up' className='footerSocials flex'>
                            <AiOutlineTwitter className='icon'></AiOutlineTwitter>
                            <AiFillYoutube className='icon'></AiFillYoutube>
                            <AiFillInstagram className='icon'></AiFillInstagram>
                            <FaTripadvisor className='icon'></FaTripadvisor>
                        </div>
                    </div>
                    <div className='footerLinks grid'>
                        <div data-aos='fade-up' data-aos-duration='3000' className='linkGroup'>
                            <span className='groupTitle'>
                                OUR AGENCY
                            </span>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                SERVICES
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                INSURANCE
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                AGENCY
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                TOURISM
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                PAYMENT
                            </li>
                        </div>



                        <div data-aos='fade-up' data-aos-duration='4000' className='linkGroup'>
                            <span className='groupTitle'>
                                PARTNERS
                            </span>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                BOOKINGS
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                RENTCARS
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                HOSTELWORLD
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                TRIVAGO
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                TRIPADVISOR
                            </li>
                        </div>


                        <div data-aos='fade-up' data-aos-duration='5000' className='linkGroup'>
                            <span className='groupTitle'>
                                LAST MINUTE
                            </span>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                LONDON
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                CALIFORNIA
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                INDONISIA
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                INDIA
                            </li>
                            <li className='footerList flex'>
                                <FiChevronRight className='icon'></FiChevronRight>
                                BANGLADESH
                            </li>
                        </div>
                    </div>


                    <div className='footerDiv flex'>
                        <small>BEST TRAVEL WEBSITE</small>
                        <small>COPYRIGHTS RESERVED -RESEARCH RIDER 2023</small>

                    </div>
                </div>

            </div>

        </section>
    );
};

export default Footer;