import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Main from "./Components/Main/Main";
import Home from "./Components/Home/Home";

function App() {
  return (
    <div className="App">
      <>
        <Navbar></Navbar>
        <Home></Home>
        <Main></Main>
        <Footer></Footer>
        {/* <HomeM></HomeM> */}
        {/* <SignUp></SignUp> */}

        {/* <Toggle></Toggle> */}
      </>
    </div>
  );
}

export default App;
